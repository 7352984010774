import { library, dom } from "@fortawesome/fontawesome-svg-core";
import * as faRegular from "@fortawesome/pro-regular-svg-icons";
import * as faSolid from "@fortawesome/pro-solid-svg-icons";
import * as faBrands from "@fortawesome/free-brands-svg-icons";

// All the icons we want to use.
library.add(
  faBrands.faCcMastercard,
  faBrands.faCcVisa,
  faBrands.faGithub,
  faBrands.faGoogle,
  faBrands.faLinkedin,
  faBrands.faMicrosoft,
  faBrands.faYoutube,
  faRegular.fa1,
  faRegular.fa2,
  faRegular.fa3,
  faRegular.faAddressCard,
  faRegular.faAngleLeft,
  faRegular.faAngleRight,
  faRegular.faAnglesLeft,
  faRegular.faAnglesRight,
  faRegular.faAnglesUpDown,
  faRegular.faArrowPointer,
  faRegular.faArrowRightFromBracket,
  faRegular.faArrowUpFromLine,
  faRegular.faArrowsRotate,
  faRegular.faBank,
  faRegular.faBars,
  faRegular.faBasketShopping,
  faRegular.faBell,
  faRegular.faBox,
  faRegular.faBuildings,
  faRegular.faCalculatorSimple,
  faRegular.faCalendarCheck,
  faRegular.faCalendarStar,
  faRegular.faChartColumn,
  faRegular.faChartLine,
  faRegular.faChartUser,
  faRegular.faCheck,
  faRegular.faChevronDown,
  faRegular.faChevronRight,
  faRegular.faChevronUp,
  faRegular.faCircle,
  faRegular.faCircleCheck,
  faRegular.faCircleDot,
  faRegular.faCircleInfo,
  faRegular.faCirclePlay,
  faRegular.faCirclePlus,
  faRegular.faCircleQuestion,
  faRegular.faCircleUp,
  faRegular.faCircleXmark,
  faRegular.faClone,
  faRegular.faCloudArrowUp,
  faRegular.faCookieBite,
  faRegular.faCreditCard,
  faRegular.faCrystalBall,
  faRegular.faCube,
  faRegular.faDatabase,
  faRegular.faDisplayCode,
  faRegular.faDownload,
  faRegular.faEdit,
  faRegular.faEllipsis,
  faRegular.faEllipsisH,
  faRegular.faEnvelope,
  faRegular.faEnvelopeDot,
  faRegular.faEnvelopeOpenText,
  faRegular.faExternalLink,
  faRegular.faEye,
  faRegular.faEyeSlash,
  faRegular.faFileCsv,
  faRegular.faFileLines,
  faRegular.faFilePdf,
  faRegular.faFileZip,
  faRegular.faGear,
  faRegular.faInbox,
  faRegular.faKey,
  faRegular.faLink,
  faRegular.faListTree,
  faRegular.faPause,
  faRegular.faPeopleGroup,
  faRegular.faPlus,
  faRegular.faReceipt,
  faRegular.faRemove,
  faRegular.faShelves,
  faRegular.faSignsPost,
  faRegular.faSparkles,
  faRegular.faSpinner,
  faRegular.faSquare,
  faRegular.faSquareCheck,
  faRegular.faSwatchbook,
  faRegular.faThumbTack,
  faRegular.faTimeline,
  faRegular.faTrashCan,
  faRegular.faTriangleExclamation,
  faRegular.faUser,
  faRegular.faUsers,
  faRegular.faXmark,
  faSolid.faCircle,
  faSolid.faCircleExclamation,
  faSolid.faCirclePause,
  faSolid.faCirclePlay,
  faSolid.faCircleX,
);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();
